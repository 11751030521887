.footer-bg-left {
  background-color: #001117;
  height: 380px;
  width: 100%;
  padding: 70px 20px 50px 45px;
  border-radius: 20px;
  color: #e4dbdb;
  font-family: "Inter", sans-serif;
}
.footer-bg-right {
  background-color: #f7ebff;
  height: 380px;
  width: 100%;
  padding: 45px 40px 40px 40px;
  font-size: var(--font-14);
  border-radius: 20px;
  color: #3e3838;
  font-family: "Inter", sans-serif;
}
.footer-color-head {
  color: #8d8484;
}
