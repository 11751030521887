/* .work-sans {
  font-family: "Work Sans", serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
} */
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
.inter {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
}

.gilroy {
  /* font-family: "Gilroy-Medium", sans-serif; */
  /* font-family: "Gilroy-Regular", sans-serif; */
  font-family: "Gilroy-bold", sans-serif;
}
.btn-common {
  background-color: var(--main-color);
  border: none;
  color: white;
  padding: 15px 21px;
  border-radius: 10px;
  /* font-size: var(--font-12); */
  font-weight: bold;
  font-family: "Manrope", sans-serif;
}
.black {
  color: black !important;
}
.btn-common-outline {
  background-color: transparent;
  border: 1px solid #94a3b8;
  padding: 15px 21px;
  border-radius: 10px;
  /* font-size: var(--font-12); */
  font-weight: bold;
  font-family: "Manrope", sans-serif;
}
.text-gray {
  color: gray !important;
}
.f-12 {
  font-size: var(--font-12);
}
.f-bold {
  font-weight: bold;
}
.f-16 {
  font-size: var(--font-16) !important;
}
.f-15 {
  font-size: 15px;
}
.f-14 {
  font-size: var(--font-14);
}
.f-24 {
  font-size: 24px;
}
.f-22 {
  font-size: 22px;
}
.gap-15 {
  margin-top: 15px;
}
.f-40 {
  font-size: 40px;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.text-color {
  color: var(--text-color);
}
.f-400 {
  font-weight: 400;
}
.font-inter {
  font-family: "Inter", sans-serif;
}
.gap-20 {
  margin-top: 20px;
}

.gap-30 {
  margin-top: 30px;
}
.gap-100 {
  margin-top: 100px;
}
.gap-110 {
  margin-top: 110px;
}
.gap-10 {
  margin-top: 10px;
}
.gap-150 {
  margin-top: 150px;
}

.gap-40 {
  margin-top: 40px;
}

.gap-50 {
  margin-top: 50px;
}
.gap-80 {
  margin-top: 80px;
}
.gap-60 {
  margin-top: 60px;
}
.gap-70 {
  margin-top: 70px;
}
.second-color {
  color: var(--second-color);
}

.main-color {
  color: var(--main-color);
}
.gray-color {
  color: gray;
}

.f-21 {
  font-size: 21px;
}
.f-20 {
  font-size: 20px;
}
.f-19 {
  font-size: 19px;
}
.f-18 {
  font-size: 18px;
}
.f-17 {
  font-size: 17px;
}
.w-38 {
  width: 38%;
}
.w-80 {
  width: 80%;
}

.font-40 {
  font-size: 40px;
}
.f-50 {
  font-size: 50px;
}
.f-55 {
  font-size: 55px;
}
.f-65 {
  font-size: 65px;
}
.manrope {
  font-family: "Manrope-bold", sans-serif;
}
.remove-btn-class {
  background-color: transparent;
  border: none;
}
.desktop-none {
  display: none !important;
}
.custom-offcanvas {
  width: 70% !important;
}

.w-70 {
  width: 70%;
}
.w-90 {
  width: 90%;
}
.w-45 {
  width: 45%;
}
.w-43 {
  width: 43%;
}
.w-55 {
  width: 55%;
}
.center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
a {
  text-decoration: none;
  color: var(--header-text-color);
  font-size: var(--font-14);
}

.provider-buyer-manageImg {
  height: 700px;
  object-fit: cover;
  border-radius: 20px;
}

.my-input {
  border: 1px solid var(--main-color);
  background-color: transparent;
  outline: none;
  padding: 21px 20px;
  border-radius: 5px;
  color: black;
  width: 100%;
}

/* .my-message-input {
  border: 1px solid white;
  background-color: transparent;
  outline: none;
  padding: 13px 20px;
  border-radius: 5px;
  color: white;
  width: 90%;
  height: 150px;
} */
/* .my-message-input::placeholder {
  color: white;
} */
.my-input::placeholder {
  color: black;
}

.custom-spinner {
  width: 16px;
  height: 16px;
}

@media only screen and (max-width: 768px) {
  .footer-bg-left {
    height: auto !important;
    padding: 54px 20px 50px 45px !important;
  }

  .provider-buyer-manageImg {
    height: 412px !important;
  }
  .footer-bg-right {
    height: auto !important;
  }
  .join-the-lending-page {
    height: auto !important;
    padding: 64px 15px !important;
  }
  .img-style-benefit {
    height: auto !important;
  }
  .my-input {
    width: 100%;
  }

  .my-message-input {
    width: 100%;
  }

  .mobile-mt-25 {
    margin-top: 25px !important;
  }
  .mobile-mt-35 {
    margin-top: 35px !important;
  }
  .mobile-mt-70 {
    margin-top: 70px !important;
  }
  .mobile-p-l-15 {
    padding-left: 15px !important;
  }
  .mobile-p-r-15 {
    padding-right: 15px !important;
  }

  .mobile-mt-10 {
    margin-top: 10px !important;
  }

  .mobile-mt-15 {
    margin-top: 15px !important;
  }

  .mobile-ms-5 {
    margin-left: 5px !important;
  }

  .mobile-ms-27 {
    margin-left: 27px !important;
  }

  .mobile-f-36 {
    font-size: 36px;
  }
  .mobile-ms-0 {
    margin-left: 0px !important;
  }
  .mobile-p-17 {
    padding: 17px !important;
  }
  .mobile-w-77 {
    width: 77% !important;
  }

  .mobile-none {
    display: none !important;
  }
  .mobile-block {
    display: block;
  }
  .header {
    height: 70px !important;
  }
  .desktop-none {
    display: block !important;
  }
  .custom-accordion .accordion-button {
    font-size: 14px !important;
  }
  .custom-accordion .accordion-body {
    font-size: 14px !important;
  }

  .custom-accordion .accordion-button::after {
    background-image: url("/public/assets/images/accordion-plus_img.png") !important;
    height: 18px !important;
    width: 18px;
  }
  .mobile-w-100 {
    width: 100% !important;
  }

  .mobile-w-90 {
    width: 90% !important;
  }
  .mobile-w-101 {
    width: 101% !important;
  }
  .mobile-w-50 {
    width: 50% !important;
  }
  .mobile-platform {
    width: 100% !important;
  }

  .mobile-w-65 {
    width: 65% !important;
  }
  .f-55 {
    font-size: 41px !important;
  }
  .f-40 {
    font-size: 35px !important;
  }
  .f-34 {
    font-size: 34px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .mobile-none {
    display: none !important;
  }
  .desktop-none {
    display: block !important;
  }
  .header {
    width: 100%;
  }
}
@media only screen and (max-width: 1300px) {
  .provider-buyer-manageImg {
    height: 600px;
  }
}
