:root {
  /* color */

  --header-text-color: #2f343d;
  --main-color: #920af2;
  --second-color: #0dbbf9;
  --text-color: #25324b;

  /* font */
  --font-14: 14px;
  --font-16: 16px;
  --font-20: 20px;
  --font-18: 18px;
  --font-12: 12px;
}
