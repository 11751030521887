.border-btn-join-lending {
  border: 1px solid white;
}
.join-the-lending-page {
  background-color: #500386;
  height: 380px;
  width: 100%;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
}
