.custom-accordion .accordion-button {
  outline: none; /* Removes outline */
  box-shadow: none; /* Removes focus shadow */
  background-color: transparent !important; /* Removes default background color */
  background-image: none !important; /* Removes default gradient */
  border: none; /* Removes border */
  position: relative; /* Enables pseudo-element positioning */
  color: #434343; /* Sets text color */
  font-family: "Gilroy-regular", sans-serif;
  font-size: 18px;
  font-weight: 600 !important;
}

.custom-accordion .accordion-button:focus,
.custom-accordion .accordion-button:active {
  outline: none; /* Ensures no outline on focus or active */
  box-shadow: none; /* Prevents any active state shadow */
  background-color: transparent !important; /* Ensures no active background */
}

.custom-accordion .accordion-item {
  border: none;
  padding: 10px 0px; /* Removes border for items */
}

.custom-accordion .accordion-header {
  font-size: 1.25rem; /* Adjusts font size */
}

.custom-accordion .accordion-body {
  padding: 1rem; /* Adds spacing for body content */
  color: #434343; /* Sets text color */
  font-family: "Gilroy-regular", sans-serif;
  font-size: 16px;
}

.custom-accordion .accordion-button::after {
  content: ""; /* Default closed icon */
  position: absolute;
  right: 1rem; /* Adjust position */
  top: 50%;
  transform: translateY(-50%) !important;
  transition: transform 0.2s, content 0.2s !important; /* Smooth transitions */
  background-image: url("/public/assets/images/accordion-plus_img.png");
}

.custom-accordion .accordion-button:not(.collapsed)::after {
  content: "";
  background-image: url("/public/assets/images/accordion-minus_img.png") !important;
}
