.header {
  height: 97px;
  display: flex;
  align-items: center;
  padding: 20px;
  color: var(--header-text-color);
  border-bottom: 1px solid rgb(215 184 237 / 50%);
  position: fixed;
  top: 0px;
  z-index: 9999;
  width: 100%;
  background-color: white;
  margin-bottom: 100px;
}
.logo-size {
  height: 37px;
  width: 154px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header span {
  font-size: var(--font-14);
  padding: 0px 25px;
  font-family: "Inter", sans-serif;
}
.header-btn {
  background-color: transparent;
  border: none;
  color: var(--main-color);
  font-size: var(--font-12);
  font-family: "Manrope", sans-serif;
}
