.toolbar {
  background-color: transparent;
  height: 200px;
  width: 100%;
  border-right: 1px solid #e3e3e3;
  padding: 14px;
  /* padding: 48px 14px; */
  font-weight: bold;
  /* border-top: 1px solid #e3e3e3; */
  border-bottom: 1px solid #e3e3e3;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.post_and_manage-bg {
  background-color: #faf2ff;
  /* height: 605px; */
  height: auto;
  width: 100%;
  border-radius: 20px;
  font-family: "Inter", sans-serif !important;
  margin-top: 60px;
  /* padding: 30px 30px; */
}
.active-toolbar {
  background-color: white;
  height: 200px;
  width: 100%;
  border-left: 3px solid var(--main-color);
  border-right: 1px solid var(--main-color);
  /* padding: 48px 14px; */
  padding: 13px;
  border-radius: 10px 0px 0px 0px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-weight: bold;
  border-bottom: 1px solid #e3e3e3;
}

.centered-section {
  flex: 8; /* Equivalent to md={8} */
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100%; /* Ensure it occupies full height of the container */
}
.padding-left-right {
  padding: 0px 50px;
}
.img-style-benefit {
  border-radius: 15px;
  box-shadow: 0px -1px 20px 0px #ecd2ed;
  height: 500px;
  width: 100%;
}
